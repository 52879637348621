import React from "react";

import { Container } from "../../styles/UI";

import * as S from "./style";

import taxdashsIllustration from "../../images/taxdashs/taxdashs-hero.png";
import taxdashsLogo from "../../images/taxdashs/taxdashs-logo-negative.svg";

import icon1 from "../../images/taxdashs/insert_chart.svg";
import icon2 from "../../images/taxdashs/cloud_done.svg";
import icon3 from "../../images/taxdashs/stack-fill.svg";

import iconDetalhes1 from "../../images/taxdashs/plug-line.svg";
import iconDetalhes2 from "../../images/taxdashs/data_usage.svg";
import iconDetalhes3 from "../../images/taxdashs/lightbulb_outline.svg";

import iconDetalhes4 from "../../images/taxdashs/tonality.svg";
import iconDetalhes5 from "../../images/taxdashs/map-2-line.svg";
import iconDetalhes6 from "../../images/taxdashs/attach_money.svg";

import illustration1 from "../../images/taxdashs/illustration1.png";
import illustration2 from "../../images/taxdashs/illustration2.png";
import illustration3 from "../../images/taxdashs/illustration3.png";

import iconBg from "../../images/taxdashs/vector.svg";
import iconBg2 from "../../images/taxdashs/vectorfat.svg";

import taxdashsBgLogo from "../../images/taxdashs/taxdashs-bg-logo.svg";

import Layout from "../../components/Layout";
import ProductCard from "../../components/ProductCard";
import QuestionWrapper from "../../components/QuestionWrapper";
import Input from "../../DesignSystem/Input";
import Button from "../../DesignSystem/Button";
import HeroCard from "../../components/HeroCard";
import StyledLink from "../../DesignSystem/StyledLink";
import TestimonialsWrapper from "../../components/Testimonials";
import ArrowNumber from "../../components/ArrowNumber";
import Clientes from "../../components/Clientes";
import ApresentacaoHome from "../home/apresentacao";

import { useInput } from "../../hooks/useInput";
import Seo from "../../components/Seo";
import axios from "axios";

export default function TaxDashs({ questions }) {
  const { value: email, bind: bindEmail } = useInput("", "email");

  const client = axios.create({
    baseURL: `https://taxcellicenseserver2.azurewebsites.net/`,
    headers: {
      "Content-Type": `application/x-www-form-urlencoded`,
    },
  });

  const handleClick = () => {
    const form = new URLSearchParams();

    form.append("source", "Testar-TaxDashs-Incompleto");
    form.append("email", email);

    client.post(`/RD_Station.aspx`, form).then((resp) => {
      console.log(resp);
    });

    if (typeof window !== "undefined")
      // window.location.href = `https://azuremarketplace.microsoft.com/pt-br/marketplace/apps/taxcel.taxdashs?tab=overview`;
      window.location.href = `https://taxcelcloud.com/teste?email=${email}`;
  };

  return (
    <Layout taxdashs>
      <Seo title="TaxDashs" />
      <S.Hero>
        <S.Container>
          <div className="cta">
            <img src={taxdashsLogo} alt="logo TaxDashs" />
            <h2>TaxDashs – BI para Tax</h2>
            <h1>Data analytics aplicado à gestão tributária</h1>
            <p>
              Visualize as suas informações fiscais em relatórios interativos no
              Power BI e obtenha insights valiosos para sua empresa.
            </p>
            <Input short negative placeholder="Seu email" {...bindEmail}>
              <Button onClick={handleClick} bg="taxdashs" color="amareloEscuro">
                Teste já
              </Button>
            </Input>
            <StyledLink
              external
              to="https://app.powerbi.com/view?r=eyJrIjoiMmM3NGQ0OTAtM2Y4YS00MTE1LWE3MjQtZjdhN2E2NjI3ZDczIiwidCI6IjdlNDU1ZDQzLTI1ZmUtNGJkOS1iMTcwLTk0N2Q4NmM1MWM1MCJ9"
              mt={16}
              color="amarelo"
              arrow
            >
              Navegue em nossos dashboards interativos
            </StyledLink>
          </div>
          <div className="illustration">
            <div className="taxdashs">
              <img src={taxdashsIllustration} alt="" />
            </div>
            <img src={taxdashsBgLogo} alt="" className="bg-logo" />
          </div>
        </S.Container>
      </S.Hero>
      <Container>
        <HeroCard>
          <div>
            <img src={icon1} alt="" />
            <p>
              Relatórios prontos para <br />
              uso e customizáveis
            </p>
          </div>
          <div>
            <img src={icon2} alt="" />
            <p>
              Dados na nuvem protegidos <br />e criptografados
            </p>
          </div>
          <div>
            <img src={icon3} alt="" />
            <p>
              Alta performance com grande <br />
              volume de dados
            </p>
          </div>
        </HeroCard>
      </Container>
      <S.VideoSection>
        <Container>
          <h3 className="column-2">
            Faça de seus relatórios fiscais uma fonte de boas notícias
          </h3>
          <div className="iframe-container column-1">
            <iframe
              src="https://www.youtube.com/embed/ZyW95F_Lb88"
              title="video-taxdashs"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p className="column-2">
            O <b>TaxDashs</b> te ajuda a inovar na gestão tributária e resolver
            problemas como:
          </p>
          <ul className="column-2">
            <li>Descontrole dos relatórios fiscais da sua empresa</li>
            <li>Falta de indicadores para tomada de decisões</li>
            <li>Alto custo e pouco valor agregado do departamento fiscal</li>
            <li>
              Dificuldade em implementar uma solução de data analytics aplicado
              a tax
            </li>
          </ul>
        </Container>
      </S.VideoSection>
      <S.Detalhes>
        <Container>
          <h3>Transforme a gestão tributária da sua empresa</h3>
          <div>
            <div className="detalhes">
              <img src={iconDetalhes1} alt="" />
              <img className="iconBg" src={iconBg} alt="" />
              <h6>Plug and Play</h6>
              <p>
                Colete e visualize dados fiscais com agilidade diretamente no
                Power BI.
              </p>
            </div>
            <div className="detalhes">
              <img src={iconDetalhes2} alt="" />
              <img className="iconBg" src={iconBg} alt="" />
              <h6>Data-driven</h6>
              <p>
                Analise grandes volumes de informação e tome decisões baseadas
                em dados.
              </p>
            </div>
            <div className="detalhes">
              <img src={iconDetalhes3} alt="" />
              <img className="iconBg" src={iconBg} alt="" />
              <h6>Insights para todos</h6>
              <p>
                Obtenha insights valiosos para o crescimento da empresa como um
                todo.
              </p>
            </div>
          </div>
          <Input placeholder="Seu email" center negative short {...bindEmail}>
            <Button onClick={handleClick}>Teste já</Button>
          </Input>
          <StyledLink to="/planos" mt={24} arrow color="secondary">
            Conheça nossos planos
          </StyledLink>
        </Container>
      </S.Detalhes>
      <S.Testimonials>
        <Container>
          <div className="cta">
            <h4>Faça a diferença através da gestão tributária</h4>
            <p>
              Com o <b>TaxDashs</b> você otimiza a criação de indicadores e
              relatórios fiscais e encontra as melhores oportunidades para
              vários setores da sua empresa.
            </p>
            <Input placeholder="Seu email" short {...bindEmail}>
              <Button onClick={handleClick}>Teste já</Button>
            </Input>
          </div>
          <TestimonialsWrapper
            produto="taxdashs"
            className="cards"
            light
            buttonColor="laranja"
          />
        </Container>
      </S.Testimonials>
      <S.ComoFunciona>
        <Container>
          <h5>
            Como <span>Funciona</span>
          </h5>
          <div className="passo">
            <ArrowNumber>01</ArrowNumber>
            <h6>
              Faça o upload dos arquivos entregues ao fisco em nosso ambiente
              Cloud
            </h6>
            <img src={illustration1} alt="" />
            <p>
              Cada cliente tem um login e senha para acessar a plataforma e
              fazer o upload dos arquivos do SPED, sem demandar nenhuma
              instalação ou ambiente de hardware local.
            </p>
          </div>
          <div className="passo reverse">
            <ArrowNumber>02</ArrowNumber>
            <h6>
              Visualize as informações fiscais em relatórios interativos no
              Power BI
            </h6>
            <img src={illustration2} alt="" />
            <p>
              Veja as informações prestadas à RFB no âmbito do SPED em
              relatórios interativos do Power BI e tenha uma visão gerencial das
              suas operações e carga tributária a partir dos dados prestados ao
              fisco.
            </p>
          </div>
          <div className="passo">
            <ArrowNumber>03</ArrowNumber>
            <h6>
              Obtenha insights estratégicos para tax e outros setores da sua
              empresa
            </h6>
            <img src={illustration3} alt="" />
            <p>
              Visualize de forma rápida e interativa as suas vendas por
              fornecedor, região, produto e tenha insights de como melhorar e
              otimizar as suas operações.
            </p>
          </div>
        </Container>
      </S.ComoFunciona>

      <S.Detalhes>
        <Container>
          <h3>O que mais você pode fazer com o TaxDashs?</h3>
          <div>
            <div className="detalhes">
              <img src={iconDetalhes4} alt="" />
              <img className="iconBg" src={iconBg2} alt="" />
              <h6>Data analytics tributário</h6>
              <p>
                Visualize a alíquota média de ICMS, IPI, PIS e COFINS por tipo
                de transação, estado de origem e de destino, cliente,
                fornecedor, CFOP, CST e produtos.
              </p>
            </div>
            <div className="detalhes">
              <img src={iconDetalhes5} alt="" />
              <img className="iconBg" src={iconBg2} alt="" />
              <h6>Mapas estratégicos</h6>
              <p>
                Identifique créditos não aproveitados e avalie a evolução dos
                saldos credores de tributos. Obtenha mapas estratégicos dos
                tributos pagos e da sua carga tributária.
              </p>
            </div>
            <div className="detalhes">
              <img src={iconDetalhes6} alt="" />
              <img className="iconBg" src={iconBg2} alt="" />
              <h6>Análise comparativa</h6>
              <p>
                Avalie a sua lucratividade por período e compare a evolução da
                sua margem de lucro em relação a suas receitas.
              </p>
            </div>
          </div>
          <Input placeholder="Seu email" center negative short {...bindEmail}>
            <Button onClick={handleClick}>Teste já</Button>
          </Input>
          <StyledLink to="/planos" mt={24} arrow color="secondary">
            Conheça nossos planos
          </StyledLink>
        </Container>
      </S.Detalhes>
      <S.Clientes>
        <Container>
          <h5 className="column-1">Clientes que confiam na Taxcel</h5>
          <h4 className="column-1">
            Mais de 730 clientes 300 grandes corporações estão transformando sua
            gestão tributária com o TaxDashs
          </h4>
          <Clientes />
          <Input placeholder="Seu email" className="column-1" {...bindEmail}>
            <Button onClick={handleClick}>Teste já</Button>
          </Input>
          <StyledLink to="/planos" className="column-1" mt={16} arrow>
            Conheça nossos planos
          </StyledLink>
        </Container>
      </S.Clientes>
      <S.Faq>
        <Container>
          <h5 className="title">Dúvidas frequentes</h5>
          <QuestionWrapper questions={questions} />
          <p className="duvidas">
            Mais dúvidas?{" "}
            <StyledLink
              external
              to="https://taxceladdins.zendesk.com/hc/pt-br"
              arrow
              color="laranja"
            >
              Acesse nosso Portal de Ajuda
            </StyledLink>
          </p>
        </Container>
      </S.Faq>
      <ApresentacaoHome white />
      <S.Solucoes>
        <Container $clip>
          <h5>Conheça nossa solução para Compliance</h5>
          <ProductCard fluid product="taxsheets" />
        </Container>
      </S.Solucoes>
      <S.Final>
        <Container>
          <h5>Inicie hoje sua jornada em direção ao Tax Transformation</h5>
          <Input center placeholder="Seu email" {...bindEmail}>
            <Button onClick={handleClick}>Teste já</Button>
          </Input>
          <StyledLink to="/planos" mt={16} arrow>
            Conheça nossos planos
          </StyledLink>
        </Container>
      </S.Final>
    </Layout>
  );
}
