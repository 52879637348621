import { graphql } from 'gatsby'
import React from 'react'
import TaxDashs from '../pagesFiles/taxdashs'

export default function taxdashs({data}) {
    return (
        <TaxDashs questions={data.allFaqJson.nodes[0].taxdashs} />
    )
}

export const query = graphql`
  {
    allFaqJson {
      nodes {
        taxdashs {
          body
          id
          question
        }
      }
    }
  }
`;